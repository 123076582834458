<template>
    <div class="wos_by_pub_box">
        <el-carousel height="260px" :autoplay="true">
            <el-carousel-item v-for="(item, index) in wosOfPubList" :key="index">
                <div class="wos_by_pub_item" @click="goToPage(item.link)">
                    <div class="wos_by_pub_item_left">
                        <div class="left_link">{{ item.link }}</div>
                        <img :src="item.pubImg" alt="" class="left_img">
                    </div>
                    <div class="wos_by_pub_item_right">
                        <img :src="item.wosImg" alt="" class="right_img1">
                        <img :src="item.pubPlusImg" alt="" class="right_img2">
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
import { wosOfPubList } from '../data';

export default {
    data() {
        return {
            wosOfPubList
        }
    },
    methods: {
        goToPage(link) {
            window.open(link, '_blank')
        }
    }
}
</script>

<style lang='scss'>
@import "../../../assets/css/minix.scss";

.wos_by_pub_box {
    @include responsive-width(1200px);
    margin: 50px 0;
    box-sizing: border-box;

    img {
        width: 100%;
    }

    .wos_by_pub_item {
        display: flex;

        &:hover {
            cursor: pointer;
        }

        .wos_by_pub_item_left {
            .left_link {
                font-size: 20px;
                margin-bottom: 20px;
                color: rgb(3, 102, 214);
                text-decoration: underline;
            }
        }

        .wos_by_pub_item_right {
            display: flex;
            flex-direction: column;
            align-items: end;

            .right_img1 {
                width: 270px;
            }

            .right_img2 {
                margin-top: 20px;
                margin-right: 30px;
                width: 190px;
            }
        }
    }
}

@media (max-width: 768px) {
    .wos_by_pub_box {
        .wos_by_pub_item {
            .wos_by_pub_item_right {
                display: none;
            }
        }
    }
}
</style>